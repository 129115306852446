<template>
  <b-card title="Yêu cầu liên hệ">
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap"
        >
            <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'page_id'">
          <p>{{ props.row.page_id }}</p>
        </span>

        <!-- Column: created_at -->
        <span v-else-if="props.column.field === 'created_at'">
            {{ new Date(props.row.created_at).toLocaleString() }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-2"
            type="submit"
            variant="danger"
            @click="deleteConfirmation(props.row.id, props.index)"
          >Xóa</b-button>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {BButton, BCard,} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import Ripple from "vue-ripple-directive";
import ToastificationContent from "../../../@core/components/toastification/ToastificationContent";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    VueGoodTable,
    BButton
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Tên',
          field: 'name',
        },
        {
          label: 'Số điện thoại',
          field: 'phone',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Nội dung',
          field: 'description',
        },
        {
          label: 'Ngày ghi nhận',
          field: 'created_at',
        },
        {
          label: 'Cập nhật',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: ''
    }
  },
  computed: {
  },
  methods: {
    deleteConfirmation(id, idx){
      this.$bvModal.msgBoxConfirm('Hành động này không thể khôi phục. Vẫn xóa?', {
        title: 'Xác nhận muốn xóa dữ liệu',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Xóa',
        cancelTitle: 'Hủy',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })
        .then(value => {
          if (value) {
            this.deleteSuport(id, idx)
          }
        })
        .catch(err => {
          console.log(err, id)
        })
    },
    deleteSuport(id, idx) {
      this.$http.delete(`/supports/${id}`)
        .then(response => {
          console.log(response.data)
          this.rows.splice(idx, 1)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Cập nhật thành công👋',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
              variant: 'danger',
            },
          })
        })
    }
  },
  created() {
    this.$http.get('/supports')
      .then(res => {
        this.rows = res.data.data
      })
  },
}
</script>
