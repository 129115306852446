<template>
  <b-row>
    <b-col cols="12">
      <support-table-basic></support-table-basic>
    </b-col>
  </b-row>
</template>

<script>
  import SupportTableBasic from "../../../table/vue-good-table/SupportTableBasic";
  import {BCol, BRow} from 'bootstrap-vue'

  export default {
    name: "Support",
    components: {SupportTableBasic, BRow, BCol}
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
